import { Howl, Howler } from 'howler';
import $ from 'jquery';

import Storeable from '../Storeable';

class Audio extends Storeable {
	constructor() {
		super();

		this.loadingBar = $('.loading-bar--inner');
		this.loadingOverlay = $('.loading-overlay');
		this.loadingText = $('.loading-text');
		this.slowLoadingText = $('.loading-text').data('slow-load');
		this.failedLoadingText = $('.loading-text').data('failed-load');
		this.loadedSources = 0;
		this.ambientSoundSources = [];
		this.isolatedSoundSources = [];

		if (window.matchMedia('(max-device-width: 992px)').matches) {
			this.populateSoundsArray('.sound-area-scrollable', this.ambientSoundSources);
			this.populateSoundsArray('#outer-container-scrollable .sound-file', this.isolatedSoundSources);
		} else {
			this.populateSoundsArray('.sound-area', this.ambientSoundSources);
			this.populateSoundsArray('#outer-container .sound-file', this.isolatedSoundSources);
		}

		// Slow loading message
		setTimeout(() => {
			if (!$('.has-error').length) {
				this.loadingText.text(this.slowLoadingText);
			}
		}, 5000);

		/**
		 * Listeners
		 */

		this.subscribeTo('ui.muted', state => {
			this.muteSound(state.ui.muted);
		});

		this.subscribeTo('channel_volumes', state => {
			for (let i = 0; i < state.channel_volumes.length; i++) {
				this.changeChannelVolume(i, state.channel_volumes[i]);
			}
		});

		this.subscribeTo('ui.overlay', state => {
			if (state.ui.overlay) {
				this.restoreStateVolumes();
			}
		});
	}

	populateSoundsArray(selector, soundType) {
		$(selector).each((i, element) => {
			const index = soundType.length;

			soundType.push(
				new Howl({
					src: [$(element).data('sound-src')],
					loop: true
				})
			);

			soundType[index].on('load', () => {
				// Loading bar for slow connections
				this.setLoaded();
			});

			soundType[index].on('loaderror', () => {
				// Display load error in overlay
				this.loadingText.addClass('has-error');
				this.loadingText.text(this.failedLoadingText);
			});
		});
	}

	setLoaded() {
		this.loadedSources += 1;

		const totalToLoad = this.ambientSoundSources.length + this.isolatedSoundSources.length;
		const loadPercent = Math.round(this.loadedSources / totalToLoad * 100);

		this.loadingBar.width(loadPercent + '%');

		if (this.loadedSources === totalToLoad) {
			// hide loading bar, begin playing sounds
			this.playAllSounds();
			this.loadingOverlay.hide();
		}
	}

	changeChannelVolume(channelIndex, channelVolume) {
		const volumeMultiplier = 0.75;

		if (channelVolume < 0.5) {
			channelVolume = channelVolume * volumeMultiplier;
		}

		// Check index exists before attempting to set volume
		if (this.ambientSoundSources[channelIndex]) {
			this.ambientSoundSources[channelIndex].volume(channelVolume);
		}
	}

	muteSound(toggle) {
		const muteIcon = $('.mute img');
		const muteIconSrcSegments = muteIcon.attr('src').split('/');
		muteIconSrcSegments.pop();

		if (toggle) {
			Howler.volume(0);
			muteIconSrcSegments.push('mute-on.svg');
		} else {
			Howler.volume(1);
			muteIconSrcSegments.push('mute-off.svg');
		}

		muteIcon.attr('src', muteIconSrcSegments.join('/'));
	}

	playAllSounds() {
		$(this.ambientSoundSources).each(function() {
			this.play();
		});
	}

	playSound(soundURL) {
		$(this.isolatedSoundSources).each(function() {
			if ($(this).attr('_src') === soundURL) {
				this.play();
			} else {
				this.stop();
			}
		});


		$(this.ambientSoundSources).each(function() {
			this.volume(0);
		});
	}

	restoreStateVolumes() {
		const volumes = this.getState().channel_volumes;

		// stop any isolated sounds that are playing
		$(this.isolatedSoundSources).each(function() {
			this.stop();
		});

		$('.playing').each(function() {
			$(this).removeClass('playing');
		});

		$('.sound-file').each(function() {
			$(this).removeClass('sound-active');
		});

		$(this.ambientSoundSources).each(function(index) {
			this.volume(volumes[index]);
		});
	}
}

export default Audio;
