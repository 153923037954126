import $ from 'jquery';

import Storeable from '../Storeable';
import { setOverlayFilter } from '../state/actions';

class HUD extends Storeable {
	constructor() {
		super();

		this.overlayCTA = $('.overlay-cta-btn');
		this.overlayInstructions = $('#instructions');
		this.closeOverlay = $('.container-overlay-close');
		this.mobileMenuTrigger = $('.mobile-menu-trigger');
		this.introLanding = $('.intro');
		this.introLocations = $('.locations')[0];
		this.cursorDock = $('.cursor-dock');
		this.languageSelector = $('.language-selector');

		if (window.matchMedia('(max-device-width: 992px)').matches) {
			this.sceneInfoCTA = $('.overlay-cta-mobile');
			this.introInstructions = $('.instructions.touch');
		} else {
			this.sceneInfoCTA = $('.overlay-cta');
			this.introInstructions = $('.instructions.desktop');
		}
	}

	init() {
		/**
		* Listeners
		*/

		if (this.introLanding.length) {
			this.introLanding.find('.btn').on('click', (e) => {
				e.preventDefault();
				this.displayIntroStep(this.introLanding, this.introInstructions);
			});

			this.introLanding.find('.skip-instructions').on('click', (e) => {
				e.preventDefault();
				this.displayIntroStep(this.introLanding, this.introLocations);
			});
		}

		if (this.introInstructions.length) {
			this.introInstructions.find('.btn').on('click', (e) => {
				e.preventDefault();
				this.displayIntroStep(this.introInstructions, this.introLocations);
			});
		}

		if (this.overlayCTA.length) {
			this.overlayCTA.each((index) => {
				this.overlayCTA[index]
					.addEventListener('click', (e) => {
						e.preventDefault();
						this.dispatch(setOverlayFilter('sounds'));
					});
			});
		}

		if (this.overlayInstructions.length) {
			this.overlayInstructions.on('click', (e) => {
				e.preventDefault();
				this.dispatch(setOverlayFilter('instructions'));
			});
		}

		if (this.mobileMenuTrigger.length) {
			this.mobileMenuTrigger.on('click', (e) => {
				e.preventDefault();
				this.toggleMobileMenu();
			});
		}

		if (this.closeOverlay.length) {
			this.closeOverlay.each((index) => {
				this.closeOverlay[index].addEventListener('click', (e) => {
					e.preventDefault();
					this.dispatch(setOverlayFilter('none'));
				});
			});

			$(window).on('keydown', (e) => {
				if (e.key === 'Esc' || e.key === 'Escape') {
					this.dispatch(setOverlayFilter('none'));
				}
			});
		}

		if (this.languageSelector.length) {
			this.languageSelector.on('click', (e) => {
				e.preventDefault();
				this.toggleLanguageSelector(e);
			});
		}

		this.subscribeTo('ui.overlay', state => {
			this.displayOverlay(state.ui.overlay);
		});
	}

	displayIntroStep(prevStep, nextStep) {
		$(prevStep).hide();
		window.scrollTo(0, 0);
		$(nextStep).show();
	}

	displayOverlay(overlay) {
		if (overlay === 'none') {
			this.sceneInfoCTA.show();
			this.cursorDock.show();
			$('.container-overlay').removeClass('show');
		} else {
			this.sceneInfoCTA.hide();
			this.cursorDock.hide();
			$('.container-overlay').addClass('show');
			$('.container-overlay').find('.btn:first-of-type').focus();

			$('.overlay-content').each(function() {
				if ($(this).is('.container-overlay-' + overlay)) {
					$(this).show();
					// ensuring overlay scrolls to top on mobile
					if (window.matchMedia('(max-device-width: 992px)').matches) {
						// only works if wrapped in a timeout... potentially a race condition going on here
						setTimeout(function() {
							$('#outer-container-scrollable .container-overlay-sounds')[0].scrollTo(0, 0);
						}, 0);
					}
				} else {
					$(this).hide();
				}
			});
		}
	}

	toggleLanguageSelector(e) {
		if ($('.languages').is(':hidden')) {
			$('.languages').show();
			$(e.target).addClass('open');
		} else {
			$('.languages').hide();
			$(e.target).removeClass('open');
		}
	}

	toggleMobileMenu() {
		const menuIcon = $(this.mobileMenuTrigger).find('img');
		const menuIconSrcSegments = menuIcon.attr('src').split('/');
		menuIconSrcSegments.pop();

		if ($('.nav').hasClass('show-mobile-nav')) {
			$('.nav').removeClass('show-mobile-nav');
			menuIconSrcSegments.push('burger-menu.svg');
		} else {
			$('.nav').addClass('show-mobile-nav');
			menuIconSrcSegments.push('close-no-bg.svg');
		}

		menuIcon.attr('src', menuIconSrcSegments.join('/'));
	}
}

export default HUD;