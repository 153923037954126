import initSubscriber from 'redux-subscriber';
import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';

import reducers from './state/reducers';

// const store = createStore(reducers, applyMiddleware(logger));
const store = createStore(reducers);

class Storeable {
	constructor() {
	}

	subscribeTo(path, callback) {
		const subscribe = initSubscriber(store);
		return subscribe(path, callback);
	}

	dispatch() {
		return store.dispatch.apply(store, arguments);
	}

	getState() {
		return store.getState.apply(store, arguments);
	}
}

export default Storeable;