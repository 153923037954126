/* global VanillaKinetic */
import $ from 'jquery';

import Draggable from 'draggable';
import Storeable from '../Storeable';
import '../lib/vanilla.kinetic';

class Cursor extends Storeable {
	constructor(settings = {}) {
		super();

		this.settings = {
			onChange: null,
			onTouchDrag: null,
			...settings
		};

		this.cursorMetrics = {
			currentX: 0,
			currentY: 0,
			initialX: 0,
			initialY: 0,
			xOffset: 0,
			yOffset: 0
		};

		this.cursorContainer = $('#cursor-container');
		this.cursor = $('#cursor');
		this.cursorDock = $('#cursor').closest('.cursor-dock');
		this.resetCursor = $('.reset');

		this.initialCursorPos;
	}

	init() {
		let active = false;

		this.initialCursorPos = this.cursor[0].getBoundingClientRect();

		if (window.matchMedia('(max-device-width: 992px)').matches) {
			/**
			 * Setup scrolling background for small screens
			 */

			const innerScrollable = $('#inner-container-scrollable');
			this.scrollableContainer = new VanillaKinetic(innerScrollable[0]);

			const scroller = this.scrollableContainer._getScroller();

			// Initial positioning
			scroller.scrollLeft = 1000;
			scroller.scrollTop = 450;

			innerScrollable.on('touchmove', touchDrag.bind(this));
		} else {
			this.instantiateDraggable();
		}

		this.resetCursor.on('click', (e) => {
			e.preventDefault();
			this.cursorReset();
		});

		// cursor focus outline - only show when using keyboard
		this.cursorContainer.on('mousedown', () => {
			this.cursor.addClass('using-mouse');
		});
		this.cursorContainer.on('keydown', () => {
			this.cursor.removeClass('using-mouse');
		});

		this.cursorContainer.on('mousedown', dragStart.bind(this));
		this.cursorContainer.on('mouseup', dragEnd.bind(this));
		$('body').on('mousemove', drag.bind(this));
		this.cursorContainer.on('keyup', (e) => {
			if (e.key === 'Enter' && $(e.target).hasClass('cursor-active')) {
				this.cursorReset();
			}

			if (e.key === 'Tab' && $(document.activeElement).hasClass('sound-area')) {
				active = true;
				this.tabToSound(e);
			}
		});
		this.cursor.on('keydown', keyStart.bind(this));
		this.cursor.on('keyup', () => {
			active = false;
		});
		// set active to false if cursor leaves window
		$(document).mouseleave(function() {
			active = false;
		});

		this.cursorDock.on('keydown', (e) => {
			if (e.key === 'Enter' && $(e.target).hasClass('cursor-active')) {
				this.cursorReset();
			}
		});

		function dragStart(e) {
			if (e.type === 'touchstart') {
				this.cursorMetrics.initialX = e.touches[0].clientX - this.cursorMetrics.xOffset;
				this.cursorMetrics.initialY = e.touches[0].clientY - this.cursorMetrics.yOffset;
			} else {
				this.cursorMetrics.initialX = e.clientX - this.cursorMetrics.xOffset;
				this.cursorMetrics.initialY = e.clientY - this.cursorMetrics.yOffset;
			}

			if (e.target === this.cursor[0]) {
				active = true;
				$(e.target).closest('.cursor-dock').addClass('cursor-active');
			}
		}

		function dragEnd() {
			this.cursorMetrics.initialX = this.cursorMetrics.currentX;
			this.cursorMetrics.initialY = this.cursorMetrics.currentY;

			active = false;
		}

		function drag(e) {
			/**
			 * Updating cursor metrics for volume logic
			 */
			if (active) {
				e.preventDefault();

				this.cursorMetrics.currentX = e.clientX - this.cursorMetrics.initialX;
				this.cursorMetrics.currentY = e.clientY - this.cursorMetrics.initialY;

				this.cursorMetrics.xOffset = this.cursorMetrics.currentX;
				this.cursorMetrics.yOffset = this.cursorMetrics.currentY;

				if (typeof this.settings.onChange === 'function') {
					this.settings.onChange(this.cursor[0], this.cursorContainer[0]);
				}
			}
		}

		function touchDrag() {
			/**
			 * Getting drag position for small screens
			 */
			if (typeof this.settings.onTouchDrag === 'function') {
				this.settings.onTouchDrag(this.scrollableContainer.el);
			}
		}

		function keyStart(e) {
			const cursorBounds = e.target.getBoundingClientRect();

			this.cursorMetrics.initialX = cursorBounds.left - this.cursorMetrics.xOffset;
			this.cursorMetrics.initialY = cursorBounds.top - this.cursorMetrics.yOffset;

			switch (e.key) {
			case 'Up':
			case 'ArrowUp':
			case 'Down':
			case 'ArrowDown':
			case 'Left':
			case 'ArrowLeft':
			case 'Right':
			case 'ArrowRight':
				e.preventDefault();
				active = true;
				$(e.target).closest('.cursor-dock').addClass('cursor-active');
				this.keyMove(e, cursorBounds);
				break;
			default:
				break;
			}
		}
	}

	cursorReset() {
		this.cursorMetrics = {
			currentX: 0,
			currentY: 0,
			initialX: 0,
			initialY: 0,
			xOffset: 0,
			yOffset: 0
		};

		this.draggable.set(15, 0);

		this.cursorDock.removeClass('cursor-active');

		if (typeof this.settings.onChange === 'function') {
			this.settings.onChange(this.cursor[0], this.cursorContainer[0]);
		}
	}

	instantiateDraggable() {
		// get limits for cursor within container bounds
		const cursorContainerBounds = this.cursorContainer[0].getBoundingClientRect();
		let lowerLimitBounds = {};
		let upperLimitBounds = {};

		// use window width if cursor container being cut off
		if (window.matchMedia('(max-device-width: 1575px)').matches) {
			lowerLimitBounds = {
				x: 0 - this.initialCursorPos.left,
				y: cursorContainerBounds.top - this.initialCursorPos.top
			};

			upperLimitBounds = {
				x: window.innerWidth + lowerLimitBounds.x - this.cursor.width(),
				y: cursorContainerBounds.height
			};
		} else {
			lowerLimitBounds = {
				x: cursorContainerBounds.left - this.initialCursorPos.left,
				y: cursorContainerBounds.top - this.initialCursorPos.top
			};

			upperLimitBounds = {
				x: cursorContainerBounds.width + lowerLimitBounds.x,
				y: cursorContainerBounds.height + lowerLimitBounds.y - this.cursor.height()
			};
		}

		/**
		 * Instantiate draggable with boundary limits
		 */
		this.draggable = new Draggable(this.cursor[0], {
			limit: {
				x: [lowerLimitBounds.x, upperLimitBounds.x],
				y: [lowerLimitBounds.y, upperLimitBounds.y]
			},
			setCursor: true
		});
	}

	keyMove(e, cursorBounds) {
		let moveX = cursorBounds.left;
		let moveY = cursorBounds.top;

		switch (e.key) {
		case 'Left':
		case 'ArrowLeft':
			//left
			moveX -= 10;
			break;
		case 'Up':
		case 'ArrowUp':
			//up
			moveY -= 10;
			break;
		case 'Right':
		case 'ArrowRight':
			//right
			moveX += 10;
			break;
		case 'Down':
		case 'ArrowDown':
			//down
			moveY += 10;
			break;
		default:
			break;
		}

		this.cursorMetrics.currentX = moveX - this.cursorMetrics.initialX;
		this.cursorMetrics.currentY = moveY - this.cursorMetrics.initialY;

		this.cursorMetrics.xOffset = this.cursorMetrics.currentX;
		this.cursorMetrics.yOffset = this.cursorMetrics.currentY;

		if (typeof this.settings.onChange === 'function') {
			this.settings.onChange(this.cursor[0], this.cursorContainer[0]);
		}

		this.setTranslate(this.cursorMetrics.currentX, this.cursorMetrics.currentY);
	}

	tabToSound(e) {
		const cursorBounds = e.target.getBoundingClientRect();
		const soundArea = document.activeElement.getBoundingClientRect();
		const centrePointX = (soundArea.left + (soundArea.width / 2)) - this.initialCursorPos.left;
		const centrePointY = (soundArea.top + (soundArea.height / 2)) - $('.nav').height();

		this.cursorMetrics.initialX = cursorBounds.left - this.cursorMetrics.xOffset;
		this.cursorMetrics.initialY = cursorBounds.top - this.cursorMetrics.yOffset;
		this.cursorMetrics.currentX = centrePointX;
		this.cursorMetrics.currentY = centrePointY;
		this.cursorMetrics.xOffset = this.cursorMetrics.currentX;
		this.cursorMetrics.yOffset = this.cursorMetrics.currentY;

		this.cursorDock.addClass('cursor-active');
		this.setTranslate(this.cursorMetrics.currentX, this.cursorMetrics.currentY);

		if (typeof this.settings.onChange === 'function') {
			this.settings.onChange(this.cursor[0], this.cursorContainer[0]);
		}
	}

	setTranslate(xPos, yPos) {
		this.draggable.set(xPos, yPos);
	}
}

export default Cursor;
