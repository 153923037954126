import $ from 'jquery';

import HUD from './ui/HUD';
import Audio from './audio/Audio';
import Cursor from './ui/Cursor';
import { muteAudio, setChannelVolume } from './state/actions';
import Storeable from './Storeable';

class App extends Storeable {
	constructor() {
		super();

		this.audio = new Audio();
		this.cursor = new Cursor({
			onChange: this.cursorChange.bind(this),
			onTouchDrag: this.touchDragChange.bind(this)
		});
		this.hud = new HUD();

		this.distances = [];
		this.timers = [];
		this.targets = [];
		this.soundMetrics = {};
		this.muteToggle = $('.mute');

		this.soundsInteractive = $('#sounds-interactive');
		this.overlaySoundFiles = $('.sound-file');
		this.cursorDock = $('.cursor-dock')[0];
		this.cursorContainer = $('#cursor-container');
		this.outerScrollableContainer = $('#outer-container-scrollable');

		this.singleSoundTimeout = null;
	}

	init() {
		this.hud.init();

		// toggling class to body that will only show focus styles when using keyboard
		$('body').on('keydown', function() {
			if (!$(this).hasClass('keyboard-navigation')) {
				$(this).addClass('keyboard-navigation');
			}
		});

		$('body').on('mousedown', function() {
			if ($(this).hasClass('keyboard-navigation')) {
				$(this).removeClass('keyboard-navigation');
			}
		});

		if ($('#sounds-interactive').length) {
			/**
			 * Initialise cursor
			 */
			this.cursor.init();

			/**
			 * Set mute cookie for persisting setting across pages
			 */
			const muteCookie = document.cookie.match(new RegExp('(^| )muted=([^;]+)'));
			if (muteCookie) {
				// determine whether cookie is true or false, set mute state accordingly
				const isMuted = (muteCookie[2] === 'true');
				this.dispatch(muteAudio(isMuted));
			} else {
				document.cookie = 'muted=false;;path=/';
			}

			/** IE11 fix - individual sound svg positioning */
			const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

			if (isIE11) {
				$('.play-middle').attr('y', '3');
				$('.play-right').attr('y', '9');
			}

			/**
			 * Cursor positioning logic for audio volumes
			 */

			const targets = [];
			const cursorDockRect = this.cursorDock.getBoundingClientRect();

			if (window.matchMedia('(max-device-width: 992px)').matches) {
				/**
				 * Mobile vh workaround - see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
				 */
				const vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty('--vh', `${vh}px`);

				window.addEventListener('resize', () => {
					const vh = window.innerHeight * 0.01;
					document.documentElement.style.setProperty('--vh', `${vh}px`);
				});

				$('.sound-area-scrollable').each(function() {
					// manually creating boundingRect object as it's not working relative to scrollable parent
					targets.push({
						rect: {
							height: $(this).height(),
							width: $(this).width(),
							left: $(this)[0].offsetLeft,
							top: $(this)[0].offsetTop
						}
					});
				});
			} else {
				$('.sound-area').each(function() {
					targets.push({ rect: $(this)[0].getBoundingClientRect() });
				});

				const videoSrc = this.soundsInteractive.data('video-src');
				const videoPoster = this.soundsInteractive.data('video-poster');
				const cinemagraph = this.soundsInteractive.find('.cinemagraph');

				cinemagraph.attr('poster', videoPoster);
				cinemagraph.attr('src', videoSrc);
				cinemagraph[0].play();
			}

			this.targets = targets;

			this.targets.forEach( () => {
				this.distances.push({});
			});

			/**
			 * Listeners
			 */

			if (this.muteToggle.length) {
				this.muteToggle.each((index) => {
					this.muteToggle[index]
						.addEventListener('click', (e) => {
							e.preventDefault();
							this.dispatch(muteAudio(!this.getState().ui.muted));
							document.cookie = 'muted=' + this.getState().ui.muted + ';;path=/';
						});
				});
			}

			this.overlaySoundFiles.each((index) => {
				this.overlaySoundFiles[index].addEventListener('click', (e) => {
					e.preventDefault();
					this.playSingleSound(e.target);
				});
			});

			/**
			 * Reset cursor if dragged into dock
			 */
			this.cursorContainer.on('mouseup keyup', () => {
				if (
					this.soundMetrics.cursorX < cursorDockRect.right && this.soundMetrics.cursorX > cursorDockRect.left &&
					this.soundMetrics.cursorY < cursorDockRect.bottom && this.soundMetrics.cursorY > cursorDockRect.top
				) {
					this.cursor.cursorReset();
				}
			});
		}
	}

	cursorChange(cursor, container) {
		/**
		 * Calculate percentages
		 */

		const cursorRect = cursor.getBoundingClientRect();
		let a;

		// Loop through all elements being tested
		for (a = this.targets.length - 1; a > -1; a -= 1) {
			// get cursor center point
			this.soundMetrics.cursorX = cursorRect.left + cursorRect.width / 2;
			this.soundMetrics.cursorY = cursorRect.top + cursorRect.height / 2;

			// get sound area center point
			this.soundMetrics.soundCenterX = this.targets[a].rect.left + this.targets[a].rect.width / 2;
			this.soundMetrics.soundCenterY = this.targets[a].rect.top + this.targets[a].rect.height / 2;

			// calculate the distance and percentage using the Pythagorean Theorem
			// https://stackoverflow.com/questions/19671131/distance-in-px-between-two-elements-in-the-dom
			const distancePercSquared =
				Math.pow((this.soundMetrics.cursorX - this.soundMetrics.soundCenterX) / container.offsetWidth, 2) +
				Math.pow((this.soundMetrics.cursorY - this.soundMetrics.soundCenterY) / container.offsetHeight, 2);
			const distancePerc = 100 - (Math.sqrt(distancePercSquared) * 100);

			this.distances[a].percentage = distancePerc;
		}

		/**
		 * Call audio function to set volumes
		 */

		for (let i = 0; i < this.distances.length; i++) {
			const volumeParam = Math.round(this.distances[i].percentage) / 100;
			this.dispatch(setChannelVolume(i, volumeParam));
		}
	}

	touchDragChange(container) {
		const cursorPosX = container.scrollLeft + ($(window).width() / 2);
		const cursorPosY = container.scrollTop + (($(window).height() / 2) - $('.nav').height());
		const scrollableContainer = $(container).find('#scrollable');

		for (let a = this.targets.length - 1; a > -1; a -= 1) {
			// get sound area center points (allowing for margin values)
			this.soundMetrics.soundCenterX = this.targets[a].rect.left + (this.targets[a].rect.width / 2) + parseInt(scrollableContainer.css('marginLeft'), 10);
			this.soundMetrics.soundCenterY = this.targets[a].rect.top + (this.targets[a].rect.height / 2) + parseInt(scrollableContainer.css('marginTop'), 10);

			const distancePercSquared =
				Math.pow((cursorPosX - this.soundMetrics.soundCenterX) / container.offsetWidth, 2) +
				Math.pow((cursorPosY - this.soundMetrics.soundCenterY) / container.offsetHeight, 2);
			const distancePerc = 100 - (Math.sqrt(distancePercSquared) * 100);

			this.distances[a].percentage = distancePerc;
		}

		/**
		 * Call audio function to set volumes
		 */

		for (let i = 0; i < this.distances.length; i++) {
			const volumeParam = Math.round(this.distances[i].percentage) / 100;
			this.dispatch(setChannelVolume(i, volumeParam));
		}
	}

	playSingleSound(sound) {
		const soundAnchor = $(sound).closest('a');
		const soundFile = soundAnchor.data('sound-src');
		const soundContainer = soundAnchor.find('.sound-row');

		if (soundContainer.hasClass('playing')) {
			soundAnchor.removeClass('sound-active');
			soundContainer.removeClass('playing');
			this.audio.restoreStateVolumes();
			this.singleSoundTimeout = null;
		} else {
			$('.playing').each(function() {
				$(this).removeClass('playing');
			});

			$('.sound-file').each(function() {
				$(this).removeClass('sound-active');
			});

			soundAnchor.addClass('sound-active');
			soundContainer.addClass('playing');
			this.audio.playSound(soundFile);

			/**
			 * Play single sounds for 5 secs before returning to cursor soundscape
			 */

			if (this.singleSoundTimeout) {
				clearTimeout(this.singleSoundTimeout);
			}

			this.singleSoundTimeout = setTimeout(function() {
				this.audio.restoreStateVolumes();
			}.bind(this), 5000);
		}
	}
}

export default App;
