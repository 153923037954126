export function muteAudio(toggle) {
	return { type: 'TOGGLE_MUTE', toggle };
}

export function setOverlayFilter(filter) {
	return { type: 'OVERLAY', overlay: filter };
}

export function setChannelVolume(index, volume) {
	return { type: 'SET_CHANNEL_VOLUME', channel_index: index, channel_volume: volume };
}