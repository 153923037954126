import { combineReducers } from 'redux';

import defaultState from './default-state';

const ui = (state = defaultState.ui, action) => {
	switch (action.type) {
	case 'OVERLAY':
		return { ...state, overlay: action.overlay };
	case 'TOGGLE_MUTE':
		return { ...state, muted: action.toggle };
	default:
		return state;
	}
};

const channel_volumes = (state = defaultState.channel_volumes, action) => {
	let newState;
	switch (action.type) {
	case 'SET_CHANNEL_VOLUME':
		newState = [...state];

		for (let i = 0; i < newState.length; i++) {
			if (action.channel_index === i) {
				newState[i] = action.channel_volume;
			}
		}
		return newState;
	default:
		return state;
	}
};

export default combineReducers({
	ui,
	channel_volumes
});